import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User as ConfigCatUser } from 'configcat-common';
import { v4 as uuid } from 'uuid';

export const FEATURE_FLAG_USER_SERVICE_TOKEN = 'FEATURE_FLAG_USER_SERVICE_TOKEN';

export abstract class FeatureFlagUserBaseService {
  abstract getConfigCatUserForIdentifiedUser(): Observable<ConfigCatUser>;

  public getConfigCatUserForUnidentifiedUser(): Observable<ConfigCatUser> {
    return this.unidentifiedSessionUserId().pipe(
      map((unidentifiedSessionUserId) => new ConfigCatUser(unidentifiedSessionUserId as string)),
    );
  }

  /*
   * Returns a uuid that is being saved in the users sessionStorage and can be used as an identifier, e.g. for analytics or feature flags
   * @returns
   */
  private unidentifiedSessionUserId(): Observable<string> {
    return new Observable((subscriber) => {
      const pseudoMe = sessionStorage.getItem('sessionId') || uuid();

      sessionStorage.setItem('sessionId', pseudoMe);

      subscriber.next(pseudoMe);
    });
  }
}
