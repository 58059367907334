import { Routes } from '@angular/router';

export const appRoutes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./customer/customer-routes').then((module) => module.customerRoutes),
  },
  {
    path: 'configurator',
    loadChildren: () =>
      import('./configurator-pro/configurator-pro-routes').then(
        (module) => module.configuratorProRoutes,
      ),
  },
];
