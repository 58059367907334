import * as Sentry from '@sentry/angular-ivy';
import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { provideAnimations } from '@angular/platform-browser/animations';
import { withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideRouter, Router } from '@angular/router';
import { ErrorHandler, APP_INITIALIZER, importProvidersFrom } from '@angular/core';
import { SERVICE_ENVIRONMENT } from '@/shared/injection-tokens';
import { appRoutes } from './app/app-routes';
import { AnonymousFeatureFlagModule } from '@/shared/modules/feature-flag/anonymous-feature-flag.module';

Sentry.init({
  dsn: 'https://5b5ba86c82836a8d432fe90b838c1bf4@o449240.ingest.us.sentry.io/4506891730092032',
  environment: environment.environment,
  enabled: environment.production,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],

  // Capture all traces outside of production (qa, stage)
  // On prod we have to reduce the amount since our sentry trace budget is finite.
  // Adjust down if we are close to the limit, adjust up if we are missing vital info.
  tracesSampleRate: environment.environment === 'production' ? 0.1 : 1,
});

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      AnonymousFeatureFlagModule.withConfig({ sdkToken: environment.configCatSdkToken }),
    ),
    { provide: SERVICE_ENVIRONMENT, useValue: environment },
    // === Config for the Sentry SDK, from their documentation ===
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    provideRouter(appRoutes),
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
  ],
}).catch((err) => console.error(err));
