import { Injectable } from '@angular/core';
import { User as ConfigCatUser } from 'configcat-common';
import { EMPTY, Observable } from 'rxjs';
import { FeatureFlagUserBaseService } from './feature-flag-user.base.service';

@Injectable()
export class AnonymousFeatureFlagUserService extends FeatureFlagUserBaseService {
  constructor() {
    super();
  }

  getConfigCatUserForIdentifiedUser(): Observable<ConfigCatUser> {
    return EMPTY;
  }
}
