import { NgModule } from '@angular/core';
import { FeatureFlagDirective } from './feature-flag.directive';
import { NotDisabledFeatureFlagPipe } from './not-disabled-feature-flag.pipe';

@NgModule({
  imports: [FeatureFlagDirective, NotDisabledFeatureFlagPipe],
  providers: [],
  exports: [FeatureFlagDirective, NotDisabledFeatureFlagPipe],
})
export class FeatureFlagDeclarationsModule {}
