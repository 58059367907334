import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CONFIG_CAT_CLIENT, FeatureFlagService } from './feature-flag.service';
import { createClientWithManualPoll, DataGovernance } from 'configcat-js';
import { AnonymousFeatureFlagUserService } from './anonymous-feature-flag-user.service';
import { FeatureFlagDeclarationsModule } from './feature-flag-declarations.module';
import { FEATURE_FLAG_USER_SERVICE_TOKEN } from './feature-flag-user.base.service';

export interface FeatureFlagModuleConfig {
  sdkToken: string;
  anonymousOnly?: boolean;
}

const providers = [
  FeatureFlagService,
  { provide: FEATURE_FLAG_USER_SERVICE_TOKEN, useClass: AnonymousFeatureFlagUserService },
];

@NgModule({
  imports: [CommonModule, FeatureFlagDeclarationsModule],
  providers,
  exports: [FeatureFlagDeclarationsModule],
})
export class AnonymousFeatureFlagModule {
  static withConfig({
    sdkToken,
  }: FeatureFlagModuleConfig): ModuleWithProviders<AnonymousFeatureFlagModule> {
    const configCatClient = createClientWithManualPoll(sdkToken, {
      dataGovernance: DataGovernance.EuOnly,
    });

    return {
      ngModule: AnonymousFeatureFlagModule,
      providers: [{ provide: CONFIG_CAT_CLIENT, useValue: configCatClient }, ...providers],
    };
  }
}
