import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { combineLatest, Observable, of, timer } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  shareReplay,
  switchMap,
  map,
  startWith,
  switchMapTo,
} from 'rxjs/operators';
import { IConfigCatClient } from './vendor/config-cat.client';
import { User as ConfigCatUser } from 'configcat-common';
import {
  FeatureFlagUserBaseService,
  FEATURE_FLAG_USER_SERVICE_TOKEN,
} from './feature-flag-user.base.service';

export const CONFIG_CAT_CLIENT = 'configCatClient' as const;
const fifteenMinutesInMilliSeconds = 1000 * 60 * 15;

@Injectable()
export class FeatureFlagService {
  /**
   * This observable is responsible for the initial filling and updating of the configCatClient cache.
   * forceRefreshAsync() is only executed once every 15 minutes at max because of the shareReplay(1), regardless in how
   * many places getValue is used. If the user is on a section where no subscribe to getValue() is used it will not
   * update the cache at all. This will help us to stay in the constraints of our ConfigCat plan.
   */
  private readonly forceRefresh$: Observable<void> = timer(0, fifteenMinutesInMilliSeconds).pipe(
    switchMap(() => this.configCatClient.forceRefreshAsync()),
    shareReplay(1),
  );

  private readonly navigationEndsEvents$ = this.router.events.pipe(
    filter((event) => event instanceof NavigationEnd),
    startWith(null),
  );

  constructor(
    @Inject(CONFIG_CAT_CLIENT) private readonly configCatClient: IConfigCatClient,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>,
    @Inject(FEATURE_FLAG_USER_SERVICE_TOKEN)
    private readonly featureFlagUserService: FeatureFlagUserBaseService,
  ) {}

  /**
   * This will return a the value for the specified key. If ConfigCat isn't available it will return the defaultValue.
   * In combination with the navigationEndsEvents$ it will just return a value once a new value is pulled via the
   * forceRefresh$ and once the user navigates to a new page.
   * If useUnidentifiedSessionUserId is true, a session based user identifier will be used to identify this user for configCat.
   * This enables us to use A/B Testing for unauthenticated Users, e.g. in the configurator
   * @param key string
   * @param defaultValue T
   * @param useUnidentifiedSessionUserId boolean
   * @returns Observable<T>
   */
  getValue<T extends boolean | number | string>(
    key: string,
    defaultValue: T,
    useUnidentifiedSessionUserId = false,
  ): Observable<T> {
    // prevent configcat requests made from server side
    if (!isPlatformBrowser(this.platformId)) {
      return of(defaultValue);
    }

    return combineLatest([
      this.forceRefresh$.pipe(
        switchMapTo(
          !useUnidentifiedSessionUserId
            ? this.featureFlagUserService.getConfigCatUserForIdentifiedUser()
            : this.featureFlagUserService.getConfigCatUserForUnidentifiedUser(),
        ),
        switchMap((configCatUser: ConfigCatUser) => {
          return this.configCatClient.getValueAsync(key, defaultValue, configCatUser) as Promise<T>;
        }),
      ),
      this.navigationEndsEvents$,
    ]).pipe(
      distinctUntilChanged(([, prevEvent], [, currentEvent]) => prevEvent === currentEvent),
      map(([value]) => value),
      distinctUntilChanged(),
    );
  }

  /**
   * The logic is the same as `getValue` but without `meService.me()` in response
   * @param key string
   * @param defaultValue T
   * @returns value Observable<T> without `User`
   */
  getValueWithoutUser<T extends boolean | number | string>(
    key: string,
    defaultValue: T,
  ): Observable<T> {
    // prevent configcat requests made from server side
    if (!isPlatformBrowser(this.platformId)) {
      return of(defaultValue);
    }

    return combineLatest([
      this.forceRefresh$.pipe(
        switchMap(() => this.configCatClient.getValueAsync(key, defaultValue) as Promise<T>),
      ),
      this.navigationEndsEvents$,
    ]).pipe(
      distinctUntilChanged(([, prevEvent], [, currentEvent]) => prevEvent === currentEvent),
      map(([value]) => value),
      distinctUntilChanged(),
    );
  }
}
